@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --primaryColor: #F7463C;
  --primaryColorTranslucent: #f7453c71;
  --secColor1: #797979;
  --secColor2: #252525;
  --secColor3: #C83229;
  --secColor4: #FFFFFF;
  --secColor5: #FF4237;
  --appBg: #1c1c1c;
}

body {
  color: var(--secColor4) !important;
}

.fullPage {
  min-height: 80vh;
}

.App {
  background-color: var(--appBg);
  min-height: 100vh;
}

.MuiTypography-root {
  color: var(--secColor4);
}

.MuiAppBar-root {
  background: var(--appBg) !important;
  box-shadow: none !important;
}

.navlink {
  color: var(--secColor4) !important;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 15px;
  border-radius: 5px;
}

.navlink.active {
  background-color: #C83229 !important;
  color: var(--secColor4) !important;
}

.a2 {
  color: var(--secColor4) !important;
}

table {
  font-family: 'Montserrat', sans-serif;
}

th {
  background-color: var(--secColor2) !important;
  border-bottom-color: var(--primaryColor) !important;
  color: var(--secColor4) !important;
}

th::before {
  background-color: var(--secColor2) !important;
  border-bottom-color: var(--primaryColor) !important;
  color: var(--secColor4) !important;
  background-color: var(--primaryColor) !important;
}

td {
  background-color: var(--secColor2) !important;
  border-bottom-color: var(--primaryColor) !important;
  color: var(--secColor4) !important;
}

.ant-table-column-sorter-up {
  color: var(--secColor4) !important;
}

.ant-table-column-sorter-up.active {
  color: var(--primaryColor) !important;
}

.ant-table-column-sorter-down {
  color: var(--secColor4) !important;
}

.ant-table-column-sorter-down.active {
  color: var(--primaryColor) !important;
}

.ant-table-filter-trigger {
  color: var(--secColor4) !important;
}

.ant-pagination-item {
  color: var(--secColor4) !important;
  border-color: var(--secColor4) !important;
  background-color: var(--appBg) !important;
}

.ant-pagination-item-active {
  color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
  background-color: var(--appBg) !important;
}

.ant-pagination-item-ellipsis {
  color: var(--secColor4) !important;
  border-color: var(--secColor4) !important;
  background-color: var(--appBg) !important;
}

.ant-pagination-item-link {
  color: var(--secColor4) !important;
  border-color: var(--secColor4) !important;
  background-color: var(--appBg) !important;
}

.ant-pagination-item-link:disabled {
  color: var(--secColor1) !important;
  border-color: var(--appBg) !important;
  background-color: var(--appBg) !important;
}

.ant-table-row-expand-icon {
  color: var(--secColor4) !important;
  border-color: var(--secColor4) !important;
  background-color: var(--appBg) !important;
}

.MuiChartsAxis-line {
  stroke: var(--secColor4) !important;
}

.MuiChartsGrid-horizontalLine {
  stroke: var(--secColor1) !important;
  stroke-dasharray: 1% !important;
}

.MuiChartsGrid-verticalLine {
  stroke: var(--secColor1) !important;
  stroke-dasharray: 1% !important;
}

.MuiMenu-paper {
  background-color: var(--primaryColor) !important;
  border-radius: 10px !important;
}

.MuiBarElement-series{
  border-radius: 50px !important;
}

tspan {
  fill: var(--secColor4) !important;
}

.ant-table-filter-dropdown {
  background-color: var(--secColor2) !important;
}

.ant-dropdown-menu {
  background-color: var(--secColor2) !important;
}

.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  font-family: 'Montserrat', sans-serif;
  color: var(--secColor4) !important;
}

.ant-btn-variant-link {
  font-family: 'Montserrat', sans-serif;
}

.ant-btn-variant-link:disabled {
  color: var(--secColor1) !important;
}

.ant-btn-variant-link:not(:disabled) {
  color: var(--primaryColor) !important;
}

.ant-btn-variant-link:not(:disabled):hover {
  color: var(--primaryColorTranslucent) !important;
}

.ant-btn-variant-solid{
  font-family: 'Montserrat', sans-serif;
  background-color: var(--primaryColor) !important;
}

.ant-checkbox-inner {
  background-color: var(--primaryColorTranslucent) !important;
  border-color: var(--primaryColor) !important;
}

.ant-divider{
  border-block-color: var(--secColor1) !important;
}